import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import Navigation from '../layouts/Header';
import Footer from '../layouts/Footer';
import Contact from '../pages/Contact';
import Product from '../pages/Product';

const AppRoutes = () => {
    return (
        <Router>
            <Navigation />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="contact" element={<Contact />} />
                <Route path="product" element={<Product />} />
            </Routes>
            <Footer />
        </Router>
    );
};

export default AppRoutes;
