import React from 'react'
import { useTranslation } from 'react-i18next';

const Service = () => {
    const { t} = useTranslation();

    return (
        <>
            <section className="img-card" id="service">
                <div className="container">
                    <div className="imgcard-title">
                        <h4>{t('service.what-we')}</h4>
                        <h5>{t('service.heading-text')}</h5>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-lg-3">
                            <div className="card-info">
                                <div className="card-img">
                                    <img src={require('../assets/images/servi.jpg')} alt="img" className='img-fluid' />
                                </div>
                                <div className="card-body">
                                    <div className="body-img">
                                        <img src={require('../assets/images/cctv.png')} alt="img" className='img-fluid' />
                                    </div>
                                    <h5 className="img-text">{t('service.card1.title')}</h5>
                                    <p className="img-title">{t('service.card1.text')}
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-3">
                            <div className="card-info">
                                <div className="card-img">
                                    <img src={require('../assets/images/servi-lock.jpg')} alt="img" className='img-fluid' />
                                </div>
                                <div className="card-body">
                                    <div className="body-img">
                                        <img src={require('../assets/images/smart-door.png')} alt="img" className='img-fluid' />

                                    </div>
                                    <h5 className="img-text">{t('service.card2.title')}</h5>
                                    <p className="img-title">{t('service.card2.text')}
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-3" >
                            <div className="card-info">
                                <div className="card-img">
                                    <img src={require('../assets/images/acess.jpg')} alt="img" className='img-fluid' />
                                </div>
                                <div className="card-body">
                                    <div className="body-img">
                                        <img src={require('../assets/images/access-control.png')} alt="img" className='img-fluid' />
                                    </div>
                                    <h5 className="img-text">{t('service.card3.title')}</h5>
                                    <p className="img-title">{t('service.card3.text')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-3">
                            <div className="card-info">
                                <div className="card-img">
                                    <img src={require('../assets/images/time-services.jpg')} alt="img" className='img-fluid' />
                                </div>
                                <div className="card-body">
                                    <div className="body-img">
                                        <img src={require('../assets/images/time-attendance.png')} alt="img" className='img-fluid' />
                                    </div>
                                    <h5 className="img-text">{t('service.card4.title')}</h5>
                                    <p className="img-title">{t('service.card4.text')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="main-card-btn">
                        <Link to="#">{t('service.viewall')} </Link>
                    </div> */}
                </div>
            </section>
        </>
    );
}

export default Service;
