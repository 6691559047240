import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap';
import AppRoutes from './routes/AppRoutes';
import '../src/assets/css/style.css';
import '../src/assets/css/responsive.css';

const App = () => {
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
