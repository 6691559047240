import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const ContactDetails = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="address">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="address-card">
                                <div className='adrees-title'>
                                    <img src={require('../assets/images/tel-1.png')} alt="contact-img" className='img-fluid' />
                                    <h2>{t('contact-page.call')}</h2>
                                </div>
                                <h6 className='address-contact'><i class="fa fa-map-marker" aria-hidden="true"></i> {t('contact-page.add1')}</h6>
                                <ul className='contact-number'>
                                    <li><Link to="tel:0944604604">0944604604</Link></li>
                                    <li><Link to="tel:0927271793">0927271793</Link></li>
                                </ul>
                                <h6 className='address-contact'><i class="fa fa-map-marker" aria-hidden="true"></i> {t('contact-page.add2')}</h6>
                                <ul className='contact-number'>
                                    <li><Link to="tel:0912364000">0912364000</Link></li>
                                    <li><Link to="tel:0912374000">0912374000</Link></li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 col-12">
                            <div className="address-card">
                                <div className='adrees-title'>
                                    <img src={require('../assets/images/mail-2.png')} alt="contact-img" className='img-fluid' />
                                    <h2>{t('contact-page.mail')}</h2>
                                </div>
                                <Link to="mailto:mohammed@futuresolutions.ly">{t('mail')}</Link>
                            </div>
                        </div> */}
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="address-card">
                                <div className='adrees-title'>
                                    <img src={require('../assets/images/map-3.png')} alt="contact-img" className='img-fluid' />
                                    <h2>{t('contact-page.location')}</h2>
                                </div>
                                <h6 className='address-contact'><i class="fa fa-map-marker" aria-hidden="true"></i> {t('contact-page.add1')}</h6>
                                <ul className='contact-number'>
                                    <li><Link >{t('contact-page.benghazibranch')}</Link></li>
                                </ul>
                                <h6 className='address-contact'><i class="fa fa-map-marker" aria-hidden="true"></i> {t('contact-page.add2')}</h6>
                                <ul className='contact-number'>
                                    <li><Link >{t('contact-page.tripolibranch')}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactDetails
