import React from 'react'
import partnerLogo1 from '../assets/images/partner-one.png';
import partnerLogo2 from '../assets/images/partner-two.png';
import partnerLogo3 from '../assets/images/partner-three.png';
import partnerLogo4 from '../assets/images/partner-four.png';
import partnerLogo5 from '../assets/images/partner-five.png';
import partnerLogo6 from '../assets/images/partner-six.png';
import partnerLogo7 from '../assets/images/partner-seven.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const Partners = () => {
    const slidebar = {
        slidesToShow: 4,
        slidesToScroll: 1,
        Arrows: false,
        dots: false,
        padding:10,
        autoplay:true,
        autoplaySpeed: 1000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    Arrows: false,
                },
            },
            {
                breakpoint:991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>
            <section className="partner">
                <div className="container">
                    <div className="partner-back">
                        <div className='slider-content-partner'>
                            <Slider {...slidebar}>
                                <div className="dummy-img">
                                    <img src={partnerLogo1} alt="partner-logo" className="img-fluid" />
                                </div>
                                <div className="dummy-img">
                                    <img src={partnerLogo2} alt="partner-logo" className="img-fluid" />
                                </div>
                                <div className="dummy-img">
                                    <img src={partnerLogo3} alt="partner-logo" className="img-fluid" />
                                </div>
                                <div className="dummy-img">
                                    <img src={partnerLogo4} alt="partner-logo" className="img-fluid" />
                                </div>
                                <div className="dummy-img">
                                    <img src={partnerLogo5} alt="partner-logo" className="img-fluid" />
                                </div>
                                <div className="dummy-img">
                                    <img src={partnerLogo6} alt="partner-logo" className="img-fluid" />
                                </div>
                                <div className="dummy-img">
                                    <img src={partnerLogo7} alt="partner-logo" className="img-fluid" />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Partners;
