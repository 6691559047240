import React from 'react'
import { useTranslation } from 'react-i18next';

const ProductBanner = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className="product-banner">
                <div className="product-overly"></div>
                <div className="container">
                    <div className="product-text">
                        <h1>{t('product-page.product-banner')}</h1>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductBanner
