import React from 'react'
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const Banner = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <section className="banner">
        <div className="overly"></div>
        <Container>
          <div className="banner-text">
            <h1>{t('banner-section.banner1.title')}</h1>
            <p> {t('banner-section.banner1.text')}</p>
          </div>
          <div className='scroll-btn'>
            <Link to="#aboutus" className="go-down-btn" onClick={() => document.getElementById('aboutus')?.scrollIntoView({ behavior: 'smooth' })}>
              <div className='shape-one'>
                <img src={i18n.language === 'ar'?require('../assets/images/arabic-scroll.png'):require('../assets/images/download.png')} alt="shape-one" className="img-fluid" />
              </div>
              <div className='scroll-icon'>
                <i className="bi bi-mouse"></i>
              </div>
            </Link>
          </div>

        </Container>
      </section>
    </>
  )
}

export default Banner
