import React from 'react'
import { useTranslation } from 'react-i18next';

const ContactBanner = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="contect-banner">
                <div className="contect-overly"></div>
                <div className="container">
                    <div className="contact-text">
                        <h1>{t('contact-page.contact-banner')}</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactBanner
