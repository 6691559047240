import React from 'react'
import { useTranslation } from 'react-i18next';

const ProductCard = () => {
    const { t } = useTranslation();
    return (
        <>
            <section className="product-card">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="product-info">
                                <div className="product-img">
                                    <img src={require('../assets/images/product-card.png')} alt="img" className='img-fluid' />
                                </div>
                                <div className="product-body">
                                    <div className="product-body-img">
                                        <div className="body-left">
                                            <img src={require('../assets/images/cctv.png')} alt="img" className='img-fluid' />

                                        </div>
                                        <div className="body-right">
                                            <img src={require('../assets/images/product-img.png')} alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                    <h5>{t('servicepage.servicecard1.title')}</h5>
                                    <p>
                                        {t('servicepage.servicecard1.text')}
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="product-info">
                                <div className="product-img">
                                    <img src={require('../assets/images/network-solutions.jpg')} alt="img" className='img-fluid' />
                                </div>
                                <div className="product-body">
                                    <div className="product-body-img">
                                        <div className="body-left">
                                            <img src={require('../assets/images/consumer-centric.png')} alt="img" className='img-fluid' />

                                        </div>
                                        <div className="body-right">
                                            <img src={require('../assets/images/product-img.png')} alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                    <h5>{t('servicepage.servicecard2.title')}</h5>
                                    <p>
                                        {t('servicepage.servicecard2.text')}
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="product-info">
                                <div className="product-img">
                                    <img src={require('../assets/images/advance-solution.jpg')} alt="img" className='img-fluid' />
                                </div>
                                <div className="product-body">
                                    <div className="product-body-img">
                                        <div className="body-left">
                                            <img src={require('../assets/images/cctv.png')} alt="img" className='img-fluid' />

                                        </div>
                                        <div className="body-right">
                                            <img src={require('../assets/images/product-img.png')} alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                    <h5>{t('servicepage.servicecard3.title')}</h5>
                                    <p>
                                        {t('servicepage.servicecard3.text')}
                                    </p>

                                </div>
                            </div>
                        </div>



                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="product-info">
                                <div className="product-img">
                                    <img src={require('../assets/images/support.jpg')} alt="img" className='img-fluid' />
                                </div>
                                <div className="product-body">
                                    <div className="product-body-img">
                                        <div className="body-left">
                                            <img src={require('../assets/images/support.png')} alt="img" className='img-fluid' />

                                        </div>
                                        <div className="body-right">
                                            <img src={require('../assets/images/product-img.png')} alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                    <h5>{t('servicepage.servicecard4.title')}</h5>
                                    <p>
                                        {t('servicepage.servicecard4.text')}
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="product-info">
                                <div className="product-img">
                                    <img src={require('../assets/images/technology.jpg')} alt="img" className='img-fluid' />
                                </div>
                                <div className="product-body">
                                    <div className="product-body-img">
                                        <div className="body-left">
                                            <img src={require('../assets/images/technical.png')} alt="img" className='img-fluid' />

                                        </div>
                                        <div className="body-right">
                                            <img src={require('../assets/images/product-img.png')} alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                    <h5>{t('servicepage.servicecard5.title')}</h5>
                                    <p>
                                        {t('servicepage.servicecard5.text')}
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="product-info">
                                <div className="product-img">
                                    <img src={require('../assets/images/monitoring.jpg')} alt="img" className='img-fluid' />
                                </div>
                                <div className="product-body">
                                    <div className="product-body-img">
                                        <div className="body-left">
                                            <img src={require('../assets/images/access-control.png')} alt="img" className='img-fluid' />

                                        </div>
                                        <div className="body-right">
                                            <img src={require('../assets/images/product-img.png')} alt="img" className='img-fluid' />
                                        </div>
                                    </div>
                                    <h5>{t('servicepage.servicecard6.title')}</h5>
                                    <p>
                                        {t('servicepage.servicecard6.text')}
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductCard;
