import React, { useState, useEffect, useCallback } from "react";
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import logoImage from '../assets/images/logo.png';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("en");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();


  const changeLanguage = useCallback((lng) => {
    i18n.changeLanguage(lng);
    document.body.dir = lng === 'ar' ? 'rtl' : 'ltr';
    localStorage.setItem('language', lng);

    const linkElement = document.getElementById('dynamic-stylesheet');
    if (linkElement) {
      linkElement.href = lng === 'ar' ? '/rtl.css' : '/ltr.css';
    }

    setLanguage(lng);
  }, [i18n]);


  // Load language from localStorage on mount
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en';
    changeLanguage(savedLanguage);
  }, [changeLanguage]);

  // Handles internal navigation
  const handleNavigate = (path) => {
    navigate(path);
    setShowOffcanvas(false); // Close offcanvas after navigation
  };

  // Scroll to section with smooth effect
  const handleScrollToSection = (sectionId) => {
    setShowOffcanvas(false); // Close menu first
  
    if (location.pathname !== "/") {
      navigate("/"); // Navigate to home first
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 500); // Increase delay to ensure the page is rendered
    } else {
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 300); // Delay ensures the DOM settles before scrolling
    }
  };
  

  return (
    <header>
      {/* Top Bar */}
      <div className="top-menu">
        <Container>
          <div className="top-info">
            <div className="top-text">
              <ul>
                <li>
                  <Link to="tel:0944604604">
                    <i className="fa fa-phone" aria-hidden="true"></i> {t('call')}: 0944604604
                  </Link>
                </li>
                <li>
                  <Link to="mailto:mohammed@futuresolutions.ly">
                    <i className="fa fa-envelope-o" aria-hidden="true"></i> {t('message')}: {t('mail')} | {t('mail2')}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="top-icon">
              <ul>
                <li><Link to="https://www.facebook.com/HlwlAlmstqblAlrqmy"><i className="fa fa-facebook-official"></i></Link></li>
                <li><Link><i className="fa fa-instagram"></i></Link></li>
                <li><Link><i className="fa fa-linkedin"></i></Link></li>
                <li><Link><i className="fa fa-youtube-play"></i></Link></li>
                <li><Link><i className="fa fa-twitter"></i></Link></li>
              </ul>
            </div>
          </div>
        </Container>
      </div>

      {/* Navigation Bar */}
      <Container>
      <Navbar expand="lg" className="">
        
          <Navbar.Brand onClick={() => handleNavigate('/')} style={{ cursor: "pointer" }}>
            <img src={logoImage} alt="logo" className="img-fluid" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={() => setShowOffcanvas(true)} />

          <Navbar.Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} id="offcanvasNavbar" placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="ms-auto">
                <Nav.Link onClick={() => handleNavigate("/")}>{t('nav.home')}</Nav.Link>
                <Nav.Link onClick={() => handleScrollToSection("aboutus")}>{t('nav.about-us')}</Nav.Link>
                <Nav.Link onClick={() => handleNavigate("/product")}>{t('nav.product')}</Nav.Link>
                <Nav.Link onClick={() => handleScrollToSection("casestudies")}>{t('nav.casestudies')}</Nav.Link>
                <Nav.Link onClick={() => handleNavigate("/contact")}>{t('nav.contact-us')}</Nav.Link>

                {/* Language Dropdown */}
                <div className="language-dropdown">
                  <button className="dropdown-btn" onClick={() => setShowDropdown(!showDropdown)}>
                    <i className="fa fa-language"></i> {language === 'en' ? "English" : "العربية"}
                    <i className="fa fa-chevron-down"></i>
                  </button>
                  <ul className={`dropdown-content ${showDropdown ? "d-block" : "d-none"}`}>
                    <li onClick={() => { changeLanguage("en"); setShowDropdown(false); }} data-lang="en">English</li>
                    <li onClick={() => { changeLanguage("ar"); setShowDropdown(false); }} data-lang="ar">العربية</li>
                  </ul>
                </div>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        
      </Navbar>
      </Container>
    </header>
  );
};

export default Header;
