import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const About = () => {
  const { t } = useTranslation();

  // Counter component as a child of About
  const Counter = ({ start, end, interval, id, label }) => {
    const [current, setCurrent] = useState(start);

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrent((prev) => {
          if (prev < end) {
            return prev + 1;
          } else {
            clearInterval(intervalId); // Clear interval once the count reaches the 'end'
            return prev;
          }
        });
      }, interval);

      return () => clearInterval(intervalId); // Cleanup on unmount
    }, [end, interval]);

    return (
      <div className="col-md-4">
        <div className="project-count">
          <h3 id={id} className="counter">
            {current} +
          </h3>
          <h4>{label}</h4>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="about" id='aboutus'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="about-left">
                <h5 className="about-title">{t('about-us.sub-title')}</h5>
                <h3 className="about-data">
                  {t('about-us.heading-title')}
                </h3>
                <h6 className="about-text">
                  {t('about-us.text1')}
                </h6>
                <p className="about-peregraph">
                  {t('about-us.text2')}
                </p>
                <p className="about-peregraph">
                  {t('about-us.text3')}
                </p>
                <div className="about-btn"><Link to="#">{t('about-us.read-more')}</Link></div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-img">
                <img
                  src={require('../assets/images/about.jpg')}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>

          <div className="about-counter">
            <div className="row">
              <Counter start={1} end={16} interval={50} id="counter1" label={t('counter.project-1')} />
              <Counter start={1} end={450} interval={50} id="counter2" label={t('counter.project-2')} />
              <Counter start={1} end={35} interval={50} id="counter3" label={t('counter.project-3')} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
