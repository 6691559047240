import React from 'react'
import ContactBanner from '../components/ContactBanner';
import ContactDetails from '../components/ContactDetails';
import ContactForm from '../components/ContactForm';

const Contact = () => {
  return (
    <>
        <ContactBanner />
        <ContactDetails />
        <ContactForm />
    </>
  )
}

export default Contact;
