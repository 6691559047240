import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const ContactForm = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="form-info">
                <div className="container">
                    <div className="form-text">
                        <h3>{t('contact-page.get-in')}</h3>
                        <h4>{t('contact-page.form-title')}</h4>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-md-7">
                            <div className="form-data">
                                <div className="row">
                                    <div className="col-md-6">
                                        <input className="form-control" type="text" placeholder={t('contact-page.name')} aria-label=" input example" />
                                    </div>
                                    <div className="col-md-6">
                                        <input className="form-control" type="text" placeholder={t('contact-page.email-address')} aria-label=" input example" />
                                    </div>
                                    <div className="col-md-6"><input className="form-control" type="text" placeholder={t('contact-page.phone-number')} aria-label=" input example" /></div>
                                    <div className="col-md-6"><input className="form-control" type="text" placeholder={t('contact-page.product')} aria-label=" input example" /></div>
                                    <div className="col-12"> <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder={t('contact-page.message')}></textarea>
                                        <div className="col-12"><button><Link >{t('contact-page.submit')}</Link></button></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-5">
                            <div className="form-right">

                                <img src={require('../assets/images/form-img.png')} alt="img" className=" form-img" />
                                <div className="right-text">
                                    <div className="technical-img">
                                        <img src={require('../assets/images/technical.png')} alt="img" className='img-fluid' />
                                    </div>
                                    <h5>{t('contact-page.quick-interaction')}</h5>
                                    <p>{t('contact-page.quick-text')}</p>
                                    <div className="form-btn">
                                        <img src={require('../assets/images/whatspp.png')} alt="img" className='img-fluid' />
                                        <Link to="https://wa.me/message/V54Q572W7OL3I1">{t('contact-page.whatsapp-message')}</Link>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactForm;
