import React from 'react'
import Banner from '../components/HomeBanner';
import About from '../components/HomeAbout';
import Service from '../components/HomeService';
import WhyChoose from '../components/HomeWhyChoose';
import Partners from '../components/HomePartners';
import CaseStudy from '../components/HomeCaseStudy';

const HomePage = () => {
  return (
    <>
        <Banner />
        <About />
        <Service />
        <WhyChoose />
        <CaseStudy />
        <Partners />
    </>
  )
}

export default HomePage;
