import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
        const element = document.getElementById(location.hash.replace("#", ""));
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }
}, [location]);
  // Scroll to section smoothly
  const handleScrollToSection = (sectionId) => {
    if (location.pathname !== "/") {
      navigate("/"); // First, navigate to the home page
      setTimeout(() => {
        document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
      }, 100); // Delay to ensure navigation happens before scrolling
    } else {
      document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-lg-5">
              <div className="footer-info">
                <img src={require('../assets/images/logo-2.png')} alt="img" className='img-fluid' />
                <p>
                  {t('footer.footer-statement')}
                </p>
                <div className="footer-icon">
                  <ul>
                    <li><Link to="https://www.facebook.com/HlwlAlmstqblAlrqmy"><i className="fa fa-facebook-official" aria-hidden="true"></i></Link>
                    </li>
                    <li><Link><i className="fa fa-twitter" aria-hidden="true"></i></Link>
                    </li>
                    <li><Link><i className="fa fa-youtube-play" aria-hidden="true"></i></Link>
                    </li>
                    <li><Link><i className="fa fa-linkedin" aria-hidden="true"></i></Link>
                    </li>
                    <li><Link><i className="fa fa-instagram" aria-hidden="true"></i></Link>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-3">
              <div className="footer-link">
                <h4>{t('footer.use-link')}</h4>
                <ul>
                  <li>
                    <Link to="/">
                      <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                      <h3>{t('nav.home')}</h3>
                    </Link>
                  </li>
                  <li>
                    <div className='section-link' onClick={() => handleScrollToSection("aboutus")}>
                      <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                      <h3>{t('nav.about-us')}</h3>
                    </div>
                  </li>
                  <li>
                    <Link to="/product">
                      <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                      <h3>{t('nav.product')}</h3>
                    </Link>
                  </li>
                  <li>
                    <div className='section-link' onClick={() => handleScrollToSection("casestudies")}>
                      <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                      <h3>{t('nav.casestudies')}</h3>
                    </div>
                  </li>
                  <li>
                    <Link to="/contact">
                      <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                      <h3>{t('nav.contact-us')}</h3>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="footer-link">
                <h4>{t('footer.our-service')}</h4>
                <ul>
                  <li>
                    <Link onClick={() => handleScrollToSection("service")}>
                      <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                      <h3>{t('footer.service1')}</h3>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => handleScrollToSection("service")}>
                      <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                      <h3>{t('footer.service2')}</h3>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => handleScrollToSection("service")}>
                      <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                      <h3>{t('footer.service3')}</h3>
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => handleScrollToSection("service")}>
                      <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                      <h3>{t('footer.service4')}</h3>
                    </Link>
                  </li>
                </ul>
              </div>

            </div> */}
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="footer-contect">
                <h4>{t('nav.contact-us')}</h4>

                <ul>
                  <li>
                    <h3><img src={require('../assets/images/map.png')} alt="img" className='img-fluid' /> {t('contact-page.add1')}</h3>
                    <div className='footer-address'>
                      <Link > {t('contact-page.benghazibranch')}</Link>
                    </div>
                    <ul className='footer-contact'>
                      <li>
                        <Link to="tel:0944604604">0944604604</Link>
                      </li>
                      <li>
                        <Link to="tel:0927271793">0927271793</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h3><img src={require('../assets/images/map.png')} alt="img" className='img-fluid' /> {t('contact-page.add2')}</h3>
                    <div className='footer-address'>
                      <Link > {t('contact-page.tripolibranch')}</Link>
                    </div>
                    <ul className='footer-contact'>
                      <li>
                        <Link to="tel:0912364000">0912364000</Link>
                      </li>
                      <li>
                        <Link to="tel:0942374000">0942374000</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div className="footer-bottom"></div>
          <div className="copy-right">
            <h6>© {t('footer.copy-right')}</h6>
          </div>
          |</div>
      </footer >
    </>
  )
}

export default Footer
