import React from 'react'
import ProductBanner from '../components/ProductBanner'
import ProductCard from '../components/ProductCard'

const Product = () => {
  return (
    <>
        <ProductBanner />
        <ProductCard />
    </>
  )
}

export default Product
