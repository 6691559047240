import React from 'react'
import { useTranslation } from 'react-i18next';

const WhyChoose = () => {
    const { t } = useTranslation();

    return (
        <>
            <section className="why-choose">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="service-data">
                                <h5>{t('why-choose.sub-title')}</h5>
                                <h3>{t('why-choose.heading-text')}</h3>
                                <h6>
                                    {t('why-choose.text')}
                                </h6>
                                <div className="service-datalist">
                                    <ul>
                                        <li>
                                            <div className="list-img">
                                                <img src={require('../assets/images/company.png')} alt="support" className='img-fluid' />
                                            </div>
                                            <div className="list-text">
                                                <h4>{t('why-choose.list1.title')}
                                                </h4>
                                                <p>
                                                    {t('why-choose.list1.text')}
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="list-img">
                                                <img src={require('../assets/images/support.png')} alt="support" className='img-fluid' />
                                            </div>
                                            <div className="list-text">
                                                <h4>{t('why-choose.list2.title')}</h4>
                                                <p>
                                                    {t('why-choose.list2.text')}
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="list-img">
                                                <img src={require('../assets/images/emerging.png')} alt="support" className='img-fluid' />
                                            </div>
                                            <div className="list-text">
                                                <h4>{t('why-choose.list3.title')}</h4>
                                                <p>
                                                    {t('why-choose.list3.text')}
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="list-img">
                                                <img src={require('../assets/images/consumer-centric.png')} alt="support" className='img-fluid' />
                                            </div>
                                            <div className="list-text">
                                                <h4>{t('why-choose.list4.title')}</h4>
                                                <p>
                                                    {t('why-choose.list4.text')}
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="service-img text-end">
                                    <img src={require('../assets/images/why-02.jpg')} alt="service" className='img-fluid main-img' />
                                <div className="service-img-postion">
                                    <img src={require('../assets/images/why-01.jpg')} alt="service" className='img-fluid' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChoose;
