import React from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';


const CaseStudy = () => {
    const { t } = useTranslation();
    const slidebar = {
        slidesToShow: 2,
        slidesToScroll: 2,
        Arrows: false,
        dots: true,
        autoplaySpeed: 1500,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    Arrows: false,
                },
            },
            {
                breakpoint:991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>
            <section className='case-study' id="casestudies">
                <div className='study-img'>
                    <img src={require('../assets/images/about-05.jpg')} alt='img' className='img-fluid' />
                </div>
                <Container>

                    <Row className='align-items-center'>
                        <Col className='offset-md-4' md={8}>
                            <div className="slider-text" >
                                <h4>{t('recent-work.sub-title')}</h4>
                                <h5>{t('recent-work.heading-text')}</h5>
                            </div>
                            <div className='slider-content'>
                                <Slider {...slidebar}>
                                    <div className='study-box'>
                                        <div className='study-content'>
                                            <div className='study-title'>
                                                <h4>{t('casestudy.card1.title')}</h4>
                                            </div>
                                            <h5>{t('casestudy.solution')}</h5>
                                            <p>{t('casestudy.card1.solutiontext')}</p>
                                            <h5>{t('casestudy.impact')}</h5>
                                            <p>{t('casestudy.card1.impacttext')}</p>
                                        </div>
                                    </div>
                                    <div className='study-box'>
                                        <div className='study-content'>
                                            <div className='study-title'>
                                                <h4>{t('casestudy.card2.title')}</h4>
                                            </div>
                                            <h5>{t('casestudy.solution')}</h5>
                                            <p>{t('casestudy.card2.solutiontext')}</p>
                                            <h5>{t('casestudy.impact')}</h5>
                                            <p>{t('casestudy.card2.impacttext')}</p>
                                        </div>
                                    </div>
                                    <div className='study-box'>
                                        <div className='study-content'>
                                            <div className='study-title'>
                                                <h4>{t('casestudy.card3.title')}</h4>
                                            </div>
                                            <h5>{t('casestudy.solution')}</h5>
                                            <p>{t('casestudy.card3.solutiontext')}</p>
                                            <h5>{t('casestudy.impact')}</h5>
                                            <p>{t('casestudy.card3.impacttext')}</p>
                                        </div>
                                    </div>
                                    <div className='study-box'>
                                        <div className='study-content'>
                                            <div className='study-title'>
                                                <h4>{t('casestudy.card4.title')}</h4>
                                            </div>
                                            <h5>{t('casestudy.solution')}</h5>
                                            <p>{t('casestudy.card4.solutiontext')}</p>
                                            <h5>{t('casestudy.impact')}</h5>
                                            <p>{t('casestudy.card4.impacttext')}</p>
                                        </div>
                                    </div>
                                    <div className='study-box'>
                                        <div className='study-content'>
                                            <div className='study-title'>
                                                <h4>{t('casestudy.card5.title')}</h4>
                                            </div>
                                            <h5>{t('casestudy.solution')}</h5>
                                            <p>{t('casestudy.card5.solutiontext')}</p>
                                            <h5>{t('casestudy.impact')}</h5>
                                            <p>{t('casestudy.card5.impacttext')}</p>
                                        </div>
                                    </div>
                                    <div className='study-box'>
                                        <div className='study-content'>
                                            <div className='study-title'>
                                                <h4>{t('casestudy.card6.title')}</h4>
                                            </div>
                                            <h5>{t('casestudy.solution')}</h5>
                                            <p>{t('casestudy.card6.solutiontext')}</p>
                                            <h5>{t('casestudy.impact')}</h5>
                                            <p>{t('casestudy.card6.impacttext')}</p>
                                        </div>
                                    </div>
                                    <div className='study-box'>
                                        <div className='study-content'>
                                            <div className='study-title'>
                                                <h4>{t('casestudy.card7.title')}</h4>
                                            </div>
                                            <h5>{t('casestudy.solution')}</h5>
                                            <p>{t('casestudy.card7.solutiontext')}</p>
                                            <h5>{t('casestudy.impact')}</h5>
                                            <p>{t('casestudy.card7.impacttext')}</p>
                                        </div>
                                    </div>
                                    <div className='study-box'>
                                        <div className='study-content'>
                                            <div className='study-title'>
                                                <h4>{t('casestudy.card8.title')}</h4>
                                            </div>
                                            <h5>{t('casestudy.solution')}</h5>
                                            <p>{t('casestudy.card8.solutiontext')}</p>
                                            <h5>{t('casestudy.impact')}</h5>
                                            <p>{t('casestudy.card8.impacttext')}</p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default CaseStudy;
